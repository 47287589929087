<template>
  <div v-if="userInfo.fullname" class="body">
    <b-tabs horizontal nav-wrapper-class="nav-bottom">
      <b-tab active title="User Info">
        <b-col>
          <h1 class="serverName text-primary ml-1 mt-2">
            <Gravatar
              class="avatar mr-1"
              :hash="JSON.stringify(email)"
              alt="Nobody"
              :size="70"
            />
            {{ `${userInfo.fullname.toUpperCase()}` }}
          </h1>
        </b-col>
        <!-- <b-button class="mr-2" variant="relief-info" @click="handleEdit">
          EDIT
        </b-button> -->
        <b-col cols="5">
          <b-row class="d-flex justify-content-between ml-0 mr-0 mt-2">
            <h5>
              {{ `Identifier: ${userInfo.userId}` }}
            </h5>
            <h5>
              {{ `Created at: ${userInfo.created_at}` }}
            </h5>
            <h5>
              {{
                `Role: ${
                  userInfo.ability.id
                } - ${userInfo.ability.label.toUpperCase()}`
              }}
            </h5>
          </b-row>
        </b-col>
        <hr class="my-2" />

        <b-col cols="12">
          <b-row class="mb-1">
            <b-col lg="3" md="4" sm="12" cols="12">
              <b-form-group label="User Fullname" label-for="user-fullname">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="UserIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="server-name"
                    placeholder="User Fullname"
                    :value="`    ${userInfo.fullname}`"
                    readonly
                  />
                  <b-input-group-append is-text>
                    <b-button
                      variant="ml-1 btn btn-warning text-white py-25 px-75"
                      @click="editUser('Fullname')"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col lg="3" md="4" sm="12" cols="12">
              <b-form-group label="Username" label-for="user-username">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="UserIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="user-username"
                    placeholder="Username"
                    readonly
                    :value="`  ${userInfo.username}`"
                  />
                  <b-input-group-append is-text>
                    <b-button
                      variant="ml-1 btn btn-warning text-white py-25 px-75"
                      @click="editUser('Username')"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col lg="4" md="4" sm="12" cols="12">
              <b-form-group label="Password" label-for="user-password">
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="KeyIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="user-password"
                    placeholder="User Password"
                    readonly
                    value="************"
                  />
                  <b-input-group-append is-text>
                    <b-button
                      variant="ml-1 btn btn-warning text-white py-25 px-75"
                      @click="handleEditPassword('Password')"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- <hr class="my-1" /> -->
          <b-row class="mb-1">
            <b-col lg="6" md="8" sm="12" cols="12">
              <b-form-group label="Email" label-for="user-email">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MailIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="user-email"
                    placeholder="User Email"
                    readonly
                    :value="`  ${userInfo.email}`"
                  />
                  <b-input-group-append is-text>
                    <b-button
                      variant="ml-1 btn btn-warning text-white py-25 px-75"
                      @click="editUser('Email')"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col lg="4" md="4" sm="12" cols="12">
              <b-form-group label="Phone" label-for="user-phone">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="PhoneIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    readonly
                    id="user-phone"
                    placeholder="  User Phone"
                    :value="`  ${userInfo.phone}`"
                  />
                  <b-input-group-append is-text>
                    <b-button
                      variant="ml-1 btn btn-warning text-white py-25 px-75"
                      @click="editUser('Phone')"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-tab>
      <b-tab v-if="tableFields.IPWhitelist" title="IP Whitelist">
        <BsTable
          tableName="IPWhitelist"
          :fields="tableFields.IPWhitelist"
          :actions="actions.IPWhitelist"
          :has_paginator="true"
          :show_filter="true"
        />
      </b-tab>
      <b-tab v-if="tableFields.userSessions" title="Active Sessions">
        <BsTable
          tableName="userSessions"
          :fields="tableFields.userSessions"
          :actions="actions.userSessions"
          :has_paginator="true"
          :show_filter="true"
        />
      </b-tab>
      <b-tab
        v-if="tableFields.userSessions && isUserGod()"
        title="User Management"
      >
        <validation-observer ref="userSearchValidation">
          <b-form class="mt-2" @submit.prevent>
            <b-col cols="12">
              <b-row>
                <b-col lg="3" md="3" sm="4" cols="12">
                  <b-form-group label="Search Type" label-for="search-type">
                    <validation-provider
                      #default="{ errors }"
                      name="search type"
                      vid="type"
                      rules="required"
                    >
                      <b-form-select
                        :options="searchTypeOptions"
                        v-model="selectedSearchType"
                        @change="activeTable ? handleSubmit() : null"
                      >
                      </b-form-select>
                      <small name="username" class="text-danger">{{
                        errors[0]
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col lg="3" md="3" sm="4" cols="12">
                  <b-form-group label="Search by" label-for="search-user">
                    <validation-provider
                      #default="{ errors }"
                      name="search by"
                      vid="username"
                      rules="required"
                    >
                      <b-form-select
                        :disabled="!selectedSearchType"
                        :options="searchByOptions"
                        v-model="searchBy"
                      >
                      </b-form-select>
                      <small name="username" class="text-danger">{{
                        errors[0]
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col lg="3" md="3" sm="4" cols="12">
                  <!-- username -->
                  <b-form-group label="Search an User" label-for="search-user">
                    <validation-provider
                      #default="{ errors }"
                      name="search user"
                      vid="username"
                      rules="required"
                    >
                      <v-select
                        :disabled="!searchBy"
                        :options="select2Options"
                        @search="onSearch"
                        :reduce="(option) => option.id"
                        :label="searchBy.toLowerCase()"
                        v-model="selectedUser"
                        @input="activeTable ? handleSubmit() : null"
                      >
                        <template slot="no-options">
                          Type at least 3 letters to search by {{ searchBy }}
                        </template>
                      </v-select>
                      <small name="username" class="text-danger">{{
                        errors[0]
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col lg="3" md="3" sm="4" cols="6">
                  <b-button
                    variant="relief-primary"
                    class="mt-2"
                    @click="handleSubmit"
                  >
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                  <b-button
                    variant="relief-secondary"
                    class="mt-2 ml-2"
                    @click="handleClear()"
                  >
                    Clear
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-form>
        </validation-observer>
        <div v-if="activeTable">
          <BsTable
            :tableName="`userManagement${searchType}`"
            :fields="tableFields[searchType]"
            :actions="actions[searchType]"
            :has_paginator="true"
            :show_filter="true"
          />
        </div>
      </b-tab>
      <b-tab
        v-if="tableFields.userSessions && isUserGod()"
        title="Create User"
      > 
        <create-user/>

      </b-tab>
    </b-tabs>
    <base-modal />
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  resetObserver,
} from "vee-validate";
import BsTable from "@/layouts/components/BsTable.vue";
import BaseModal from "@/layouts/components/BaseModal.vue";
import custom from "@/custom";
import vSelect from "vue-select";
import { makeToast } from "@/layouts/components/Popups";
import { mapGetters } from "vuex";
import moment from "moment";
import CreateUser from "@/views/pages/profile/CreateUser.vue"

import {
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BForm,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BRow,
  BCol,
  BTabs,
  BTab,
  BFormSelect,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Gravatar from "vue-gravatar";
import md5 from "crypto-js/md5";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    BInputGroupAppend,
    BFormTextarea,
    BaseModal,
    Gravatar,
    BTabs,
    BTab,
    BsTable,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    CreateUser
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      userInfo: [],
      email: "",
      searchType: "",
      searchTypeOptions: [],
      searchByOptions: [],
      select2Options: [],
      searchBy: "",
      selectedUser: null,
      activeTable: false,
      selectedSearchType: null,
      // t,
      tableFields: {
        IPWhitelist: [
          {
            key: "id",
            label: this.$t("common.table.fields.id"),
            sortable: true,
          },
          {
            key: "ip",
            label: this.$t("profile.table.fields.ip"),
            sortable: true,
          },
          {
            key: "status",
            label: this.$t("common.table.fields.status"),
            sortable: true,
          },
          {
            key: "created_at",
            label: this.$t("common.table.fields.created_at"),
            sortable: true,
          },
          {
            key: "actions",
            label: this.$t("common.table.fields.actions"),
          },
        ],
        userSessions: [
          {
            key: "ip",
            label: this.$t("profile.table.fields.ip"),
            sortable: true,
          },
          {
            key: "last_activity",
            label: this.$t("profile.table.fields.last_activity"),
            sortable: true,
          },
          {
            key: "created_at",
            label: this.$t("common.table.fields.created_at"),
            sortable: true,
          },
          {
            key: "actions",
            label: this.$t("common.table.fields.actions"),
          },
        ],
        ip_whitelist: [
          {
            key: "id",
            label: this.$t("common.table.fields.id"),
            sortable: true,
          },
          {
            key: "ip",
            label: this.$t("profile.table.fields.ip"),
            sortable: true,
          },
          {
            key: "status",
            label: this.$t("common.table.fields.status"),
            sortable: true,
          },
          {
            key: "created_at",
            label: this.$t("common.table.fields.created_at"),
            sortable: true,
          },
          {
            key: "actions",
            label: this.$t("common.table.fields.actions"),
          },
        ],
        session: [
          {
            key: "ip",
            label: this.$t("profile.table.fields.ip"),
            sortable: true,
          },
          {
            key: "last_activity",
            label: this.$t("profile.table.fields.last_activity"),
            sortable: true,
          },
          {
            key: "created_at",
            label: this.$t("common.table.fields.created_at"),
            sortable: true,
          },
          {
            key: "actions",
            label: this.$t("common.table.fields.actions"),
          },
        ],
      },
      actions: {
        IPWhitelist: custom.tableActions.IPWhitelist,
        userSessions: custom.tableActions.userSessions,
        ip_whitelist: custom.tableActions.ip_whitelist,
        session: custom.tableActions.session,
      },
    };
  },

  created() {
    this.$root.$refs[this.$route.meta.pageTitle] = this;
  },

  mounted() {
    this.init();
  },

  computed: {
    ...mapGetters(["getLoggedUser", "getAbility"]),
  },

  methods: {
    init() {
      this.fetchUserInfo();
    },
    async fetchUserInfo() {
      if (this.getLoggedUser.userId) {
        this.userInfo = await this.getLoggedUser;
        this.email = md5(this.getLoggedUser.email);
        this.fetchUserSearchType();
        this.fetchUserSearchBy();
      } else {
        this.$store.dispatch("getLoggedUserAgain").then(async (response) => {
          this.userInfo = await response;
          this.fetchUserSearchType();
          this.fetchUserSearchBy();
        });
      }
    },
    editUser(action) {
      this.$store.dispatch("setUserAction", action).then(() => {
        this.$store
          .dispatch("setModalObj", `editUser${action}`)
          .then(() => {
            this.$store.dispatch("setActiveModal", true);
          })
          .catch((err) => {
            console.erro(err);
          });
      });
    },
    handleEditPassword() {
      let modalData = custom.confirmModalStructure["editUserPassword"];
      this.$bvModal
        .msgBoxConfirm(modalData.message, modalData.config)
        .then((value) => {
          if (value) {
            this.$store
              .dispatch("sendResetPasswordSolicitation")
              .then((response) => {
                makeToast(custom.infoMessages["reset_password_solicitation"]);
              })
              .catch((error) => {
                makeToast(
                  custom.errorMessages[
                    error.code ? error.code : "reset_password_solicitation"
                  ]
                );
              });
          } else {
            makeToast(custom.errorMessages[action]);
          }
        });
    },
    isUserGod() {
      if (this.getAbility) {
        return this.getAbility.id === 666 ? true : false;
      } else {
        return this.userInfo.enum_access_level.id === 666 ? true : false;
      }
    },
    fetchUserSearchType() {
      if (this.isUserGod()) {
        this.$store.dispatch("fetchUserSearchType").then((response) => {
          this.searchTypeOptions = response.items;
        });
      }
    },
    fetchUserSearchBy() {
      if (this.isUserGod()) {
        this.$store.dispatch("fetchUserSearchBy").then((response) => {
          this.searchByOptions = response.items;
        });
      }
    },
    onSearch(data) {
      if (data.length > 2) {
        this.$store
          .dispatch(`getUserBy${this.searchBy}`, data)
          .then((response) => {
            this.select2Options = response;
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    async handleSubmit() {
      this.$refs.userSearchValidation.validate().then(async (success) => {
        if (success) {
          this.searchType = await this.selectedSearchType;
          this.activeTable = false;
          this.$store
            .dispatch("setUserToManage", {
              searchType: this.searchType,
              userId: this.selectedUser,
            })
            .then(() => {
              this.activeTable = true;
            });
        }
      });
    },
    handleClear() {
      this.selectedSearchType = null;
      this.searchType = null;
      this.searchBy = null;
      this.selectedUser = null;
      this.activeTable = false;
    },
    // handleEdit() {
    //   this.$router.push({
    //     name: "edit-server",
    //     params: { serverId: this.$route.params.serverId },
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
</style>